

* { padding: 0; margin: 0; }
html, body, #fullheight, #root {
    min-height: 100% !important;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
  font-variant: small-caps;
  font-family: "papyrus";
  text-align: center;
  margin: 0;
  background: #a1793a;
  color: black;
}

h4 {
  color: #a1793a;
}

h5 {
  font-variant: small-caps;
  font-family: "papyrus";
  text-align: center;
  background: black;
  color: white;
  margin-left: 10%;
  margin-right: 10%;
}

p {
  font-family: "Copperplate Gothic Light";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  padding: 0;
}

/* HEADER */
header {
  display: contents;
  width: 100%;
}

.links {
  text-align: center;
  padding-right: 10px;
  margin-left: 15px;
  color: black;
  font-variant: small-caps;
}

.links:hover {
  color: #a1793a;
}

li {
  list-style-type: none;
  color: white;
}

h4 {
  font-family: "Copperplate Gothic Light";
  font-size: 20px;
}



/* FOOTER */
.github .fab {
  color: white;
  font-size: 40px;
  padding: 3px;
}

.linkedin .fab {
  color: #0e76a8;
  font-size: 40px;
  padding: 3px;
}

.stack-overflow {
  font-size: 40px;
}

.harp .harp-icon {
  font-size: 40px;
  padding: 3px;
}

.stack-overflow img:hover {
  height: 50px;
}

.github .fab:hover {
  font-size: 50px;
}

.linkedin .fab:hover {
  font-size: 50px;
}

.harp img:hover {
  height: 50px;
}

footer {
  font-family: "Lucida Handwriting";
  text-align: center;
  background: #a1793a;
  position: relative;
}

footer ul {
  padding: 0%;
}
footer ul li {
  display: inline-flex;
  padding: 10px;
  list-style: none;
}

/* ABOUT ME */
.about-me {
  align-items: center;
}

.about-body {
  display: inline-flex;
  align-items: center;
  width: 75%;
  margin-left: 15%;
  margin-right: 15%;
}

.profile-pic {
  border-radius: 30px;
  margin: 10px;
  border: #a1793a 2px solid;
}

.profile-pic:hover {
  height: 300px;
}

.about-me p {
  text-align: center;
}

.about-me h3 {
  padding: 5px;
}

/* PROJECTS */
.portfolio-carousel {
  text-align: center;
  margin: 15px;
}

.card {
  border: #a1793a double 6px;
  border-radius: 25px;
  width: 25%;
  display: inline-flex;
  align-items: center;
  text-align: center;
  margin: auto;
}

.thumb {
  background: whitesmoke;
  border-radius: 15px;
  height: 4rem;
}

#harp-thumb {
  height: 3.5rem;
  width: 1.5rem;
}

.card-title {
  font-family: "Copperplate Gothic Light";
}

.card-title {
  font-family: "Copperplate Gothic Light";
  font-size: 18px;
  color: #a1793a;
}

.card-title a:hover {
  color: whitesmoke;
}

.card-text p {
  font-size: 14px;
  color: #a1793a;
}

.card-body p,
ul li,
a {
  color: black;
}

.card-body a i {
  padding-right: 5px;
}

.fa-github {
  font-size: 26px;
  color: #a1793a;
}

.fa-github:hover {
  color: black;
}

.fa-globe {
  color: #a1793a;
  font-size: 26px;
}

.fa-globe:hover {
  color: black;
}

#harp-card {
  width: 7em;
}

.badge {
  margin: 3px;
}

button {
  background-color: lightskyblue;
  border-radius: 35px;
  color: black;
  font-size: 13px;
}

/* EDUCATION */
.education {
  text-align: center;
}

/* SKILLS */
.resume a {
  color: #b54a85;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-variant: small-caps;
}

.resume a:hover {
  color: white;
}

.skills {
  text-align: center;
  font-family: "Copperplate Gothic Light";
}

.skills ul {
  justify-content: space-evenly;
}
/* CONTACT FORM */
form {
  
  /* display: inline-flex; */
}

form div {
  text-align: center;
}

label {
  padding: 5px;
  font-family: "Copperplate Gothic Light";
  color: white;
  margin: 10px;
}

#contact-form div a {
  color: #a1793a;
  font-family: "Copperplate Gothic Light";
}

#contact-form div a:hover {
  color: #a3507e;
}

/* EXPERIENCE */
.experience {
  display: grid;
}

.job_bullets {
  margin-left: 15%;
  margin-right: 10%;
  font-size: 14px;
}

li {
  list-style-type:disc;
}

.row {
  justify-content: space-between;
  margin-left: 10%;
  margin-right: 10%;
}

/*MEDIA QUERY FOR SMALL DESKTOP SCREENS AND SMALLER */
@media screen and (max-width: 980px) {
}

/*MEDIA QUERY FOR TABLETS AND SMALLER */
@media screen and (max-width: 768px) {
  footer {
    width: 100%;
    position: absolute;
  }

  header {
    width: 100%;
  }

  .links {
    display: inline-block;
  }

  .about-me {
    font-size: 12px;
    display: inline-block;
    text-align: center;
  }

  .about-body {
    margin: 0%;
    display: inline-block;
  }

  .profile-pic {
    height: 125px;
  }

  h1 {
    font-size: xx-large;
  }
  h2 {
    font-size: larger;
  }

  h3 {
    font-size: large;
  }

  .card {
    width: 80%;
  }

  ul li {
    font-size: 10px;
    text-align: left;
  }

}

/*MEDIA QUERY FOR MOBILE PHONES AND SMALLER */
@media screen and (max-width: 575px) {
  header {
    width: 100%;
  }

  footer {
    width: 100%;
  }   
}

/* PORTFOLIO CSS CARDS */
.fa-spin-fast {
  -webkit-animation: fa-spin-fast 0.2s infinite linear;
  animation: fa-spin-fast 0.2s infinite linear;
}
@-webkit-keyframes fa-spin-fast {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin-fast {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.material-card {
  position: relative;
  height: 0;
  padding-bottom: calc(100% - 16px);
  margin-bottom: 6.6em;
}
.material-card h2 {
  position: absolute;
  top: calc(100% - 16px);
  left: 0;
  width: 100%;
  padding: 10px 16px;
  color: #fff;
  font-size: 1.4em;
  line-height: 1.6em;
  margin: 0;
  z-index: 10;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.material-card h2 span {
  display: block;
}
.material-card h2 strong {
  font-weight: 400;
  display: block;
  font-size: 0.8em;
}
.material-card h2:before,
.material-card h2:after {
  content: " ";
  position: absolute;
  left: 0;
  top: -16px;
  width: 0;
  border: 8px solid;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.material-card h2:after {
  top: auto;
  bottom: 0;
}
@media screen and (max-width: 767px) {
  .material-card.mc-active {
    padding-bottom: 0;
    height: auto;
  }
}
.material-card.mc-active h2 {
  top: 0;
  padding: 10px 16px 10px 90px;
}
.material-card.mc-active h2:before {
  top: 0;
}
.material-card.mc-active h2:after {
  bottom: -16px;
}
.material-card .mc-content {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 16px;
  left: 16px;
  border: #00bcd4 double 4px;
  border-radius: 30px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.material-card .mc-btn-action {
  position: absolute;
  right: 16px;
  top: 16px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 5px solid;
  width: 54px;
  height: 54px;
  line-height: 44px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  z-index: 20;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.material-card.mc-active .mc-btn-action {
  top: 65px;
}
.material-card .mc-description {
  position: absolute;
  top: 100%;
  right: 30px;
  left: 30px;
  bottom: 54px;
  color: black;
  overflow: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 1.2s;
  -moz-transition: all 1.2s;
  -ms-transition: all 1.2s;
  -o-transition: all 1.2s;
  transition: all 1.2s;
}
.material-card .mc-footer {
  height: 0;
  border-radius: 30px;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.material-card .mc-footer h4 {
  position: absolute;
  top: 200px;
  left: 30px;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  -webkit-transition: all 1.4s;
  -moz-transition: all 1.4s;
  -ms-transition: all 1.4s;
  -o-transition: all 1.4s;
  transition: all 1.4s;
}
.material-card .mc-footer a {
  display: inline;
  position: relative;
  width: 52px;
  padding-right: 7px;
  height: 52px;
  margin-left: 5px;
  margin-bottom: 15px;
  font-size: 28px;
  color: #fff;
  line-height: 52px;
  text-decoration: none;
  top: 200px;
}
.material-card .mc-footer a:nth-child(1) {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.material-card .mc-footer a:nth-child(2) {
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
}
.material-card .mc-footer a:nth-child(3) {
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -ms-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.material-card .mc-footer a:nth-child(4) {
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
}
.material-card .mc-footer a:nth-child(5) {
  -webkit-transition: all 0.9s;
  -moz-transition: all 0.9s;
  -ms-transition: all 0.9s;
  -o-transition: all 0.9s;
  transition: all 0.9s;
}

.img-responsive {
  /* height: 14em; */
  display: block;
  max-width: 100%;
  height: auto;
  align-self: center;
}

.material-card .img-container {
  text-align: center;
  overflow: hidden;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 3;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.material-card.mc-active .img-container {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  left: 0;
  top: 12px;
  width: 60px;
  height: 60px;
  z-index: 20;
}
.material-card.mc-active .mc-content {
  padding-top: 5.6em;
}
@media screen and (max-width: 767px) {
  .material-card.mc-active .mc-content {
    position: relative;
    margin-right: 16px;
  }
}
.material-card.mc-active .mc-description {
  top: 50px;
  padding-top: 5.6em;
  opacity: 1;
  filter: alpha(opacity=100);
}
@media screen and (max-width: 767px) {
  .material-card.mc-active .mc-description {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    padding: 50px 30px 70px 30px;
    bottom: 0;
  }
}
.material-card.mc-active .mc-footer {
  overflow: visible;
  position: absolute;
  top: calc(100% - 16px);
  left: 16px;
  right: 0;
  height: 50px;
  /* padding-top: 15px; */
  /* padding-left: 25px; */
}
.material-card.mc-active .mc-footer a {
  top: 0;
}
.material-card.mc-active .mc-footer h4 {
  top: -32px;
}
/* card color */
.material-card.Cyan h2 {
  background-color: #00bcd4;
}
.material-card.Cyan h2:after {
  border-top-color: #00bcd4;
  border-right-color: #00bcd4;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.material-card.Cyan h2:before {
  border-top-color: transparent;
  border-right-color: #006064;
  border-bottom-color: #006064;
  border-left-color: transparent;
}
.material-card.Cyan.mc-active h2:before {
  border-top-color: transparent;
  border-right-color: #00bcd4;
  border-bottom-color: #00bcd4;
  border-left-color: transparent;
}
.material-card.Cyan.mc-active h2:after {
  border-top-color: #006064;
  border-right-color: #006064;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.material-card.Cyan .mc-btn-action {
  background-color: #00bcd4;
}
.material-card.Cyan .mc-btn-action:hover {
  background-color: #006064;
}
.material-card.Cyan .mc-footer h4 {
  color: #006064;
}
.material-card.Cyan .mc-footer a {
  /* background-color: #006064; */
}
.material-card.Cyan.mc-active .mc-content {
  background-color: #e0f7fa;
}
.material-card.Cyan.mc-active .mc-footer {
  background-color: #b2ebf2;
}
.material-card.Cyan.mc-active .mc-btn-action {
  border-color: #e0f7fa;
}